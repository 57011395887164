<template>
  <div>
    <b-card title="Votre tableau de bord">
            <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              :options="calendarOptions"
              class="full-calendar"
            />
          </div>
        </div>
      </div>
    </b-card>

  </div>
</template>

<script>
import { BTabs, BTab, BRow, BCol, BCard, BCardText } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    FullCalendar
  },
  props: ['groups'],
  data() {
    return {

    }
  },
  setup(props, {emit}) {
    let calendarOptions = {
        plugins: [ dayGridPlugin, interactionPlugin ],
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        locale: frLocale,

        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,dayGridWeek,listMonth',
        },
        events: []
    }

    let colors = {
      BOOKING_LIMIT: "#015a9c",
      DEPARTURE: "#07babd",
      PAYMENT: "#f2a900"
      

    }

    const extractEventsFromGroups = (groups) => {
      let calendarEvents = []
      groups.forEach(group => {
        
        if (group.bookingLimit) {
          calendarEvents.push({
            title: `Limite réservation - ${group.name}`,
            date: group.bookingLimit,
            backgroundColor: colors.BOOKING_LIMIT
          })
        }

        if (group.departureDate) {
          calendarEvents.push({
            title: `Départ - ${group.name}`,
            date: group.departureDate,
            backgroundColor: colors.DEPARTURE
          })
        }

        if (group.paymentLimit) {
          calendarEvents.push({
            title: `Limite paiement - ${group.name}`,
            date: group.paymentLimit,
            backgroundColor: colors.PAYMENT
          })
        }

      });
      return calendarEvents;
    }

    calendarOptions.events = extractEventsFromGroups(props.groups)

    return {
      calendarOptions
    }
  }
}
</script>
<style lang="scss">
  @import '@core/scss/vue/apps/calendar.scss';

  .superEvent {
    color:red !important;
  }

  .fc-daygrid-event {
    white-space: normal !important;
  }
</style>
