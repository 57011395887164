<template>
  <div>
      <b-row>
        <b-col cols="10">
          <b-card :title="t('dashboard.quick_actions')">
            <b-row>
              <b-col  cols="4">
                <b-card
                  class="text-center cursor-pointer card-button"
                  bg-variant="primary" text-variant="white"
                  @click="$router.push({ name: 'organisation-groups'})"
                  style="min-height:5em"
                >
                  <h4 class="text-white align-center" style="margin-bottom:0px">{{t('dashboard.group_search')}}</h4>
                </b-card>
              </b-col>
              <b-col  cols="4">
                <b-card
                  class="text-center cursor-pointer card-button"
                  img-top
                  bg-variant="info" text-variant="white"
                  @click="$router.push({ name: 'organisation-groups'})"
                >
                  <h4 class="text-white align-center" style="margin-bottom:0px">{{t('dashboard.reserve')}}</h4>
                </b-card>
              </b-col>
              <b-col  cols="4">
                <b-card
                  class="text-center cursor-pointer card-button"
                  img-top
                  bg-variant="success" text-variant="white"
                  @click="navigateToReservationList()"
                >
                  <h4 class="text-white align-center" style="margin-bottom:0px">{{t('dashboard.my_reservations')}}</h4>
                </b-card>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
        <b-row class="match-height">
          <b-col cols="8">
          <Calendar v-if="groups.length > 0" :groups=groups />
          </b-col>
          <b-col         
            lg="4"
            md="12"
          >
            <b-card title="Dernières chances">
                <b-card>
                  <h5>{{t('dashboard.90_days_departures')}}</h5>

                  <div v-if="departureDateGroups.length <= 0"  class="pl-3 mt-2"> 
                    <span><i>Aucun groupe</i></span>
                  </div>
                  <div
                    v-for="(group,index) in departureDateGroups"
                    :key="group.id"
                    class="profile-twitter-feed"
                    :class="index != 0 ? 'mt-2' : 'mt-1'"
                  >
                    <div class="d-flex justify-content-start align-items-center mb-1">
                      <b-avatar
                        size="40"
                        :src="group.images"
                        class="mr-1"
                      />
                      <div class="profile-user-info">
                        <h6 class="mb-0">
                          {{ group.name }}
                        </h6>
                        <b-link>
                          
                          <small class="text-muted">{{t('dashboard.departure')}}: <b>{{ group.departureDate }}</b></small>
                        </b-link>
                      </div>
                      <div class="profile-star ml-auto">

                        <b-button size="sm" variant="success" @click="$router.push({ path: `/groups/${group.id}` })" >{{t('dashboard.reserve')}}</b-button>
                      </div>
                    </div>
                    <b-card-text class="mb-50">
                    </b-card-text>
                    <b-link>
                      <small>
                        
                      </small>
                    </b-link>
                  </div>
                  <!-- twitter feed -->
                </b-card>

            </b-card>

            <b-card title="Vos rappels">
                <b-card>
                  <h5>{{t('dashboard.upcoming_due_date')}}</h5>

                  <!-- twitter feed -->
                  <div
                    v-for="(group,index) in paymentLimitGroups"
                    :key="group.id"
                    class="profile-twitter-feed"
                    :class="index != 0 ? 'mt-2' : 'mt-1'"
                  >
                    <div class="d-flex justify-content-start align-items-center mb-1">
                      <b-avatar
                        size="40"
                        :src="group.images"
                        class="mr-1"
                      />
                      <div class="profile-user-info">
                        <h6 class="mb-0">
                          {{ group.name }}
                        </h6>
                        <b-link>
                          <small class="text-muted"><b-badge size="sm" variant="warning">Paiement final</b-badge></small>
                        </b-link>
                      </div>
                      <div class="ml-auto text-aligh-right">
                        <b>Date limite</b>
                        <br>
                        <small>
                          {{group.paymentLimit}}
                        </small>
                      </div>
                      <div class="profile-star ml-auto">
                        <b-button size="sm" variant="outline-info" @click="$router.push({ path: `/groups/${group.id}` })" >Consulter</b-button>
                      </div>
                    </div>
                    <b-card-text class="mb-50">
                    </b-card-text>
                    <b-link>
                      <small>
                        
                      </small>
                    </b-link>
                  </div>
                  <!-- twitter feed -->
                </b-card>

            </b-card>
          </b-col>
        </b-row>

        

  </div>

</template>

<script>
import {BAvatar,BButton, BBadge, BImg, BMedia, BTabs, BTab, BRow, BCol, BCard, BCardText, BLink } from 'bootstrap-vue'
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import {onMounted, ref} from '@vue/composition-api';
import {useLocalisation} from "@/shared/composables/use-localisation";
import Calendar from "./Calendar"
import store from "@/store";
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import Roles from "@/modules/authnz/models/role";
import router from '@/router'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BAvatar, 
    BMedia,
    BImg,
    BBadge,
    BButton,
    BLink,
    Calendar
  },
  setup(props, {emit}) {
    const {getAllAvailableGroups} = useGroupApi();
    const {displayErrorMessage} = useToastNotification();
    const {t} = useLocalisation();

    const groups = ref([]);
    const paymentLimitGroups = ref([]);
    const departureDateGroups = ref([]);

    const fetchGroups = async () => {
      try {
        groups.value = await getAllAvailableGroups();
        let tmpPaymentLimitGroups = groups.value
        let tmpDepartureGroups = groups.value

        tmpPaymentLimitGroups = sortGroupsByDate(tmpPaymentLimitGroups, "paymentLimit" )
        tmpPaymentLimitGroups = filterActiveGroups(tmpPaymentLimitGroups)

        tmpDepartureGroups = sortGroupsByDate(tmpDepartureGroups, "departureDate" )
        tmpDepartureGroups = filterActiveGroups(tmpDepartureGroups)

        paymentLimitGroups.value = tmpPaymentLimitGroups.slice(0,5);
        departureDateGroups.value = tmpDepartureGroups.slice(0,5);

      } catch (e) {
        console.error(e)
        displayErrorMessage('Une erreur est survenue en essayant de chercher les groupes disponibles');
      }
    };

    const filterActiveGroups = (list) => {
      return list.filter(group => {
        let today = new Date();
        let departureDate = new Date(group.departureDate);
        return departureDate > today
      })
    }

    const sortGroupsByDate = (list, dateTerm) => {
        return list.sort(function (a, b) {
          let aDate = new Date(a[dateTerm])
          let bDate = new Date(b[dateTerm])
          if (aDate > bDate) {
            return 1;
          }

          if (aDate < bDate) {
            return -1;
          }

          if (aDate == bDate) {
            return 0;
          }
        });
    }

    const navigateToReservationList = () => {
      const CURRENT_ROLE = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]?.role;

      let reservationsRouteName = 'organisation-reservations';
      if (Roles.isAgencyRole(CURRENT_ROLE.role)) {
        reservationsRouteName = 'agency-reservations';
      }

      router.push({ name: reservationsRouteName })
    };

    onMounted(async () => fetchGroups());

    return {
      groups,
      paymentLimitGroups,
      departureDateGroups,
      navigateToReservationList,
      t
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-button {
    min-height: 5em;
  }
  .card-button:hover {
    filter: opacity(0.70);
  }

  .profile-user-info {
    width: 40%;
  }


</style>
